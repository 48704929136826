@import "./vars.scss";

.landing {
    margin-bottom: 30px;

    .header {
        padding-top: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: $tabletBP) {
            padding-top: 130px;
        }

        h1 {
            font-size: 4rem;
        }

        h2 {
            text-align: center;
            .color {
                color: $accentLight;
            }
        }

        h3 {
            margin-top: 10px;
            font-weight: 100;
            text-align: center;
        }

        .invite {
            background-color: white;
            border-radius: 10px;
            padding: 20px 50px;
            font-weight: 700;
            margin-top: 30px;
            color: black;
            text-decoration: none;
            font-size: 20px;
            transition: 0.2s;

            &:hover {
                box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.407);
            }
        }
    }

    .features {
        display: flex;
        margin-top: 70px;
        justify-content: space-around;
        gap: 10px;
        flex-wrap: nowrap;

        @media(max-width: $mobileBP) {
            margin-top: 30px;
        }

        .g {
            display: flex;
            width: 50%;
            gap: 10px;
            min-width: 300px;
            justify-content: space-around;
        }

        .feature {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            margin-bottom: 2.5px;
            max-width: 350px;
            border-radius: 10px;
            padding: 20px 10px;
            transition: 0.2s;
            border: 1px solid rgba(255, 255, 255, 0.189);


            .icon {
                width: 60px;
                height: 60px;
            }

            .head {
                font-size: 23px;
                margin-top: 20px;
                margin-bottom: 5px;
                text-align: center;
            }

            .desc {
                text-align: center;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.104);
                border: 1px solid rgba(255, 255, 255, 0.689);
            }
        }

        @media (max-width: $tabletBP) {
            flex-wrap: wrap;   

            
            .g {
                width: 100%;
            }
        }
    }
}