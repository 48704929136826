@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
@import "./vars.scss";

* {
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
    background-color: $background;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 0;

    @media (max-width: $mobileBP) {
        padding: 0 10px;
        max-width: calc(100% - 20px);
    }
}

.page {
    width: 100%;
    padding-top: 140px;

    @media (max-width: $mobileBP) {
        padding-top: 70px;
    }
}

.content {
    min-height: 100vh;
}

a {
    color: $accent;
}

nav.navbar {
    position: fixed;
    width: calc(100% - 40px);
    top: 0;
    z-index: 1;
    padding-top: 20px;
    padding-bottom: 10px;
    transition: 0.2s ease;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: $tabletBP) {
        margin: unset;
        width: 100%;
    }

    .backdrop {
        transition: 0.2s;
        background-color: $background;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }

    .container {
        background-color: rgba(255, 255, 255, 0.38);
        border-radius: 10px;
        backdrop-filter: blur(10.5px);
        padding: 17px 27px;
        display: flex;
        justify-content: space-between;

        @media (max-width: $tabletBP) {
            flex-direction: column;
            margin: 0 20px;
        }
    }

    .logo {
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
            width: 44px;
            height: 44px;
            margin-right: 10px;
        }

        h1 {
            color: #FFF;
            font-size: 30px;
            font-weight: 400;
        }

        @media (max-width: $mobileBP) {
            h1 {
                font-size: 25px;
            }

            img {
                width: 35px;
                height: 35px;
                margin-right: 10px;
            }
        }
    }

    .static {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $tabletBP) {
            width: 100%;
        }
    }

    ul.links {
        display: flex;
        align-items: center;
        list-style: none;
        margin-left: 20px;
        transition: 0.2s;
        gap: 25px;

        li a {
            color: #FFF;
            font-size: 17px;
            font-weight: 500;
            text-decoration: none;
            transition: 0.2s;

            &.active {
                font-weight: 700;
                color: $accentLight;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        li:nth-child(2) a {

        }

        @media (max-width: $tabletBP) {
            display: flex;
            overflow: hidden;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            padding: 0px;
            height: 0;
            opacity: 0;
            gap: 0;

            li {
                border-bottom: 0.5px solid gray;
                width: 100%;
                display: flex;

                a {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    text-align: center;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:first-child a {
                    padding-top: 10px;
                }

                a:hover {
                    text-decoration: none;
                }
            }

            &.expanded {
                padding: 20px 0;
                height: auto;
                opacity: 1;
            }
        }
    }

    .contactButton {
        display: flex;
        border-radius: 50px;
        background: #FFF;
        color: #000;
        align-items: center;
        justify-content: center;
        padding: 15px 33px;
        font-size: 17px;
        font-weight: 600;
        text-decoration: none;

        @media (max-width: $tabletBP) {
            display: none;
        }
    }

    .contactButton.expanded {
        display: flex;
    }

    .burgerMenu {
        all: unset;
        display: none;
        transition: 0.2s;

        img {
            width: 30px;
            height: 30px;
        }

        @media (max-width: $tabletBP) {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    &.scrolled {
        margin: unset;
        width: 100%;
        padding: 10px 0 5px 0;
        box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);

        .container {
            background-color: unset;
            backdrop-filter: blur(0px);
        }

        .backdrop {
            opacity: 1;
        }

        .links li a{
            color: white;
        }

        .logo {
            h1 {
                color: white;
            }
        }
    }
}

footer {
    margin-top: -500px;
    background-color: rgba(255, 255, 255, 0.38);

    .container {
        color: white;
        padding-bottom: 40px;
        padding-top: 40px;

        .head {
            font-weight: bold;
        }

        a {
            color: rgb(255, 255, 255);
            text-decoration: none;            
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        .cols {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .links {
                display: flex;
                gap: 50px;
                justify-content: space-between;
            }

            .col {
                display: flex;
                flex-direction: column;
                gap: 10px;

                &.main {
                    flex-direction: row;
                    align-items: flex-start;
                    width: 50%;

                    .logo {
                        width: 70px;
                        height: 70px;
                        margin-right: 10px;
                    }

                    h3 {
                        font-size: 23px;
                    }
                }
            }

            @media (max-width: $tabletBP) {
                flex-direction: column;
                
                .col.main {
                    width: 100%;
                }

                .links {
                    margin-top: 50px;
                    width: 100%;
                    justify-content: space-evenly;
                }

                .basicLinks {
                    display: none;
                }
            }
        }

        .copyright {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.blurBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -100;
    overflow: hidden;

    .point {
        position: absolute;
        background-color: $accent;
        width: 900px;
        height: 1100px;
        border-radius: 50%;
    }

    .blur {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(150px);
    }
}   