@import "./vars.scss";

.premium {
    .header {
        padding-top: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (max-width: $tabletBP) {
            padding-top: 130px;
        }

        h1 {
            font-size: 4rem;
            text-align: center;

            .color {
                color: $accentLight;
            }
        }

        p {
            text-align: center;
            max-width: 600px;
            margin-top: 10px;
        }
    }

    .pricing {
        .cards {
            margin-top: 50px;
            display: flex;
            gap: 15px;
            align-items: flex-end;
            margin-bottom: 10px;
        }

        .card {
            width: 33%;
            transition: 0.5s;

            .bestValue {
                text-align: end;
                padding: 10px;
                border-radius: 100px;
                margin-bottom: 5px;
                background-color: $background;
            }

            .bgCard {
                color: black;
                background-color: white;
                border-radius: 10px;
                padding: 30px;

                &.best {
                    background: linear-gradient(180deg, #fff 0%, rgba(170, 177, 255, 0.44) 100%);
                }

                .title {
                    font-size: 30px;
                    font-weight: 600;
                }

                .price {
                    font-size: 30px;
                    margin-top: 10px;
                    margin-bottom: 10px;

                    &.top {
                        display: none;
                    }

                    @media (max-width: $tabletBP) {
                        margin: 0;

                        &.bottom {
                            display: none;
                        }

                        &.top {
                            display: block;
                        }
                    }
                }

                .features .col {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }

                .features {
                    margin-bottom: 25px;
                }

                .feature {
                    display: flex;
                    align-items: flex-start;
                    gap: 5px;

                    .check {
                        width: 20px;
                    }
                }

                .firstBTN,
                .secondBTN {
                    padding: 10px 30px;
                    border-radius: 10px;
                    background-color: white;
                    text-decoration: none;
                    color: black;
                    font-weight: bold;
                    text-align: center;
                    transition: 0.2s;
                    display: block;

                    &:hover {
                        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.292);
                    }
                }

                .secondBTN {
                    background-color: $background;
                    color: #fff;
                }

                &.best .secondBTN {
                    background-color: white;
                    color: black;
                }

                .firstBTN {
                    display: none;
                }

                @media (max-width: $tabletBP) {
                    .desc {
                        margin-bottom: 20px;
                    }

                    .info {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            &:hover {
                transform: translateY(-10px);
            }

            &.small {
                width: 100%;

                .bgCard {
                    display: flex;
                    align-items: center;
                    background-color: rgba(255, 255, 255, 0.5);
                    border: 1.5px solid rgba(255, 255, 255, 0.693);

                    .info {
                        width: 50%;

                        .price.top {
                            display: block;
                        }

                        .price.bottom {
                            display: none;
                        }
                    }

                    .firstBTN {
                        display: unset;
                    }

                    .secondBTN {
                        display: none;
                    }

                    .desc {
                        margin-bottom: 20px;
                    }

                    .features {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }

                &:hover {
                    transform: scale(1.01);
                }
            }

            @media (max-width: $tabletBP) {
                width: 100%;

                .bgCard {
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border: 1.5px solid rgba(255, 255, 255, 0.693);

                    .info {
                        width: 30% !important;
                    }

                    .features {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        width: 70%;
                        gap: 20px;

                        .col {
                            width: 50%;
                        }
                    }

                    .firstBTN {
                        display: inline;
                    }

                    .secondBTN {
                        display: none;
                    }

                    &.best {
                        background-color: $background;
                    }

                    @media(max-width: $mobileBP) {
                        flex-direction: column;

                        .info {
                            width: 100% !important;
                        }

                        .features {
                            width: 100%;
                        }

                        .firstBTN {
                            display: none !important;
                        }

                        .secondBTN {
                            display: block !important;
                            background-color: white;
                            color: black;
                        }
                    }
                }

                &:hover {
                    transform: scale(1.01);
                }
            }
        }

        @media (max-width: $tabletBP) {
            .cards {
                flex-direction: column;
            }
        }
    }
}
