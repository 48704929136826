.law {

    .content {
        background-color: rgba(255, 255, 255, 0.2);
        padding: 20px 30px;
        border-radius: 10px;
        margin-top: 130px;
    }

    h1 {
        margin-bottom: 10px;
        font-size: 40px;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 30px;
    }

    h3 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 25px;
    }

    h4 {
        margin-top: 25px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    ul {
        padding-left: 20px;
        list-style: disc;
    }

    ol {
        list-style-type: lower-alpha;
        margin-left: 30px;
    }
}